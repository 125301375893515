<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15.5346 9.46447C17.4872 11.4171 17.4872 14.5829 15.5346 16.5355C13.5819 18.4882 10.4161 18.4882 8.46349 16.5355C6.51087 14.5829 6.51087 11.4171 8.46349 9.46447C10.4161 7.51184 13.5819 7.51184 15.5346 9.46447" stroke="currentColor" stroke-width="1.5093" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 13L8.45996 16.54" stroke="currentColor" stroke-width="1.5093" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M18.7173 6.28281C18.8638 6.42926 18.8638 6.66669 18.7173 6.81314C18.5709 6.95959 18.3334 6.95959 18.187 6.81314C18.0405 6.66669 18.0405 6.42926 18.187 6.28281C18.3334 6.13636 18.5709 6.13636 18.7173 6.28281Z" fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5.81302 19.1868C5.95946 19.3332 5.95946 19.5707 5.81302 19.7171C5.66657 19.8636 5.42913 19.8636 5.28269 19.7171C5.13624 19.5707 5.13624 19.3332 5.28269 19.1868C5.42913 19.0404 5.66657 19.0404 5.81302 19.1868Z" fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M21.3902 12.7348C21.5366 12.8813 21.5366 13.1187 21.3902 13.2652C21.2437 13.4116 21.0063 13.4116 20.8598 13.2652C20.7134 13.1187 20.7134 12.8813 20.8598 12.7348C21.0063 12.5884 21.2437 12.5884 21.3902 12.7348Z" fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3.14017 12.7348C3.28661 12.8813 3.28661 13.1187 3.14017 13.2652C2.99372 13.4116 2.75628 13.4116 2.60984 13.2652C2.46339 13.1187 2.46339 12.8813 2.60984 12.7348C2.75628 12.5884 2.99372 12.5884 3.14017 12.7348Z" fill="currentColor"/>
  <path d="M3.14017 12.7348C3.28661 12.8813 3.28661 13.1187 3.14017 13.2652C2.99372 13.4116 2.75628 13.4116 2.60984 13.2652C2.46339 13.1187 2.46339 12.8813 2.60984 12.7348C2.75628 12.5884 2.99372 12.5884 3.14017 12.7348" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M18.7173 19.1868C18.8638 19.3332 18.8638 19.5707 18.7173 19.7171C18.5709 19.8636 18.3334 19.8636 18.187 19.7171C18.0405 19.5707 18.0405 19.3332 18.187 19.1868C18.3334 19.0404 18.5709 19.0404 18.7173 19.1868Z" fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5.81302 6.28281C5.95946 6.42926 5.95946 6.66669 5.81302 6.81314C5.66657 6.95959 5.42913 6.95959 5.28269 6.81314C5.13624 6.66669 5.13624 6.42926 5.28269 6.28281C5.42913 6.13636 5.66657 6.13636 5.81302 6.28281Z" fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.2652 3.60984C12.4116 3.75628 12.4116 3.99372 12.2652 4.14017C12.1187 4.28661 11.8813 4.28661 11.7348 4.14017C11.5884 3.99372 11.5884 3.75628 11.7348 3.60984C11.8813 3.46339 12.1187 3.46339 12.2652 3.60984Z" fill="currentColor"/>
  <path d="M12.2652 3.60984C12.4116 3.75628 12.4116 3.99372 12.2652 4.14017C12.1187 4.28661 11.8813 4.28661 11.7348 4.14017C11.5884 3.99372 11.5884 3.75628 11.7348 3.60984C11.8813 3.46339 12.1187 3.46339 12.2652 3.60984" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>